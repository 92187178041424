const descriptions = [
  'User eXperience (UX) refers to enhancing the experience and satisfaction the user has beyond simple usability. It includes aspects such as graphics, design, interface, and both physical and manual interaction.',
  'Very often the success of your software product will depend on how user-friendly it is, but it goes beyond the usability of the User Interface (UI).',
  'At Nearshore Code we have a team with years of experience in providing UI/UX development services. The methods and processes we apply aim to produce additional value to your customers and your product through presentation and interactivity.',
];

const concepts = [
  {
    title: 'Architecture Planning',
    content: 'Structure is a component as important as content. Applying the right techniques makes the difference in making a digital product valuable.',
    bgColor: 'bg-concept-normal-gray',
  },
  {
    title: 'UX Roadmap Designing',
    content: 'The blueprint can ensure that you will accomplish your research and design goals on time, sticking to your budget and considering all key components.',
    bgColor: 'bg-concept-light-gray',
  },
  {
    title: 'Wireframe Designing',
    content: 'This source of information regarding layouts, visual hierarchy, information architecture and content priority allows to make a faster and smarter design.',
    bgColor: 'bg-concept-light-green',
  },
  {
    title: 'Front-end Development',
    content: 'Applying  the appropriate tools, frameworks and libraries in order to develop a well-designed and scalable product for the client to interact with.',
    bgColor: 'bg-concept-dark-gray',
  },
];

export {
  descriptions,
  concepts,
};
